(function ($) {

  "use strict";

  function SwiperSlider () {
    
    let initSingleSlider = function ($swiper) {
      $swiper.each(function () {
        let $currentSwiper = $(this);

        let headerSwiper = new Swiper($currentSwiper, {
          speed: 400,
          threshold: 30,
          navigation: {
            nextEl: $currentSwiper.find('.swiper-button-next'),
            prevEl: $currentSwiper.find('.swiper-button-prev')
          },
          slidesPerView: 1,
          centeredSlides: true,
          spaceBetween: 0,
          pagination: {
            el: $swiper.find('.swiper-pagination'),
            type: 'bullets',
          },
        });
      });
    };
    
    let init = function (parentElement) {
      // Single slider (one slider on all viewports)
      let $singleSlider = $('.js-single-slider', parentElement);
      if ($singleSlider.length) {
        initSingleSlider($singleSlider);
      }
    };

    this.init = function (element) {
      let parentElement = element ? element : $('body');
      init(parentElement);
    };
  }

  window.SwiperSlider = new SwiperSlider();

})(jQuery);
