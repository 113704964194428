(function ($) {

  "use strict";

  function Datepicker () {
    this.executeSomething = function () {
      // Code
    };

    let initDatepicker = function ($datepicker) {
      let datepickerTitleSelector = 'datepicker-title';

      $datepicker.datepicker({
        dateFormat: 'dd/mm/yy',
        autoHide: true,
        show: function () {
          let $currentPicker = $(this);
          let datepickerTitle = $currentPicker.data('title');
          
          setTimeout(function () {
            let $datepickerDrops = $('.datepicker-container');
            $datepickerDrops.each(function () {
              let $currentDrop = $(this);
              let $panel = $('.datepicker-panel', $currentDrop);

              if (datepickerTitle) {
                if (!$('.' + datepickerTitleSelector, $panel).length) {
                  $panel.prepend('<div class="datepicker-title">' + datepickerTitle + '</div>');
                }
              } else {
                $currentDrop.addClass('no-title');
              }

              $currentDrop.toggleClass('active', !$currentDrop.hasClass('datepicker-hide'));
            });
          }, 50);
        },
        hide: function() {
          MobileOverlay.dismissOverlay();
        }
      });
    };
    
    let initSingleDatepickers = function ($singleDatepicker) {
      let $field = $('.js-single-datepicker-field', $singleDatepicker);
      let $controls = $('.js-single-datepicker-control', $singleDatepicker);

      $field.each(function () {
        let $currentField = $(this);
        let dateFormat = $currentField.data('date-format');
        let date = $currentField.data('date');

        $currentField.val(moment(date, dateFormat).format('dd DD MMM'));
      });

      $controls.on('click', function () {
        let $clickedBtn = $(this);
        let $scope = $clickedBtn.closest('.js-single-datepicker-scope');
        let $singleScope = $clickedBtn.closest($singleDatepicker);
        let $scopeField = $singleScope.find($field);
        let $altField = $scopeField.siblings('.js-alt-field');
        let type = $clickedBtn.data('control');
        let date = $scopeField.data('date');
        let dateFormat = $scopeField.data('date-format');
        let newDateBE;
        let newDateFormatted;

        if (type === '-') {
          newDateBE = moment(date, dateFormat).subtract(1, 'days').format(dateFormat);
          newDateFormatted = moment(date, dateFormat).subtract(1, 'days').format('dd DD MMM');
        } else {
          newDateBE = moment(date, dateFormat).add(1, 'days').format(dateFormat);
          newDateFormatted = moment(date, dateFormat).add(1, 'days').format('dd DD MMM');
        }

        $scopeField.val(newDateFormatted);
        $scopeField.data('date', newDateBE);
        $altField.val(newDateBE);
        
        let $fromScope = $scope.find('[data-single="from"]');
        let fromDate = $fromScope.find('.js-alt-field').val();
        let $toScope = $scope.find('[data-single="to"]');
        let toDate = $toScope.find('.js-alt-field').val();
        
        if (moment(fromDate, dateFormat).isAfter(moment(toDate, dateFormat))) {
          $('[data-control="+"]', $toScope).click();
        }
      });
    };

    let init = function (parentElement) {
      let $datepicker = $('.js-datepicker', parentElement);
      let $singleDatepicker = $('.js-single-datepicker', parentElement);

      if ($datepicker.length) {
        // initDatepicker($datepicker);
      }

      // This is not actually jQuery datepicker, it is customized component which have current date, and controls for moving day backward and forward
      if ($singleDatepicker.length) {
        // initSingleDatepickers($singleDatepicker);
      }
    };

    this.init = function (element) {
      let parentElement = element ? element : $('body');
      init(parentElement);
    };
  }

  window.Datepicker = new Datepicker();

})(jQuery);
