(function ($) {

  "use strict";

  function MagnificPopup () {
    let groupSelector = '.js-magnific-group';
    let magnificLinkSelector = '.js-magnific-link';
    let magnificCustomLinkSelector = '.js-magnific-link-custom';
    
    let initMagnificPopup = function ($groups) {
      $groups.each(function() { // the containers for all your galleries
        $(this).magnificPopup({
          delegate: 'a' + magnificLinkSelector, // the selector for gallery item
          type: 'image',
          tLoading: 'Loading image #%curr%...',
          gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0,1] // Will preload 0 - before current, and 1 after the current image
          }
        });
      });
    };
    
    let initMagnificCustomTrigger = function ($trigger) {
      $trigger.on('click', function(e) {
        e.preventDefault();
        $(this).closest(groupSelector).find(magnificLinkSelector).eq(0).click();
      });
    };

    let init = function (parentElement) {
      let $popupGroups = $(groupSelector, parentElement);
      let $customTrigger = $(magnificCustomLinkSelector, parentElement);

      if ($popupGroups.length) {
        initMagnificPopup($popupGroups);
      }

      if ($customTrigger.length) {
        initMagnificCustomTrigger($customTrigger);
      }
    };

    this.init = function (element) {
      let parentElement = element ? element : $('body');
      init(parentElement);
    };
  }

  window.MagnificPopup = new MagnificPopup();

})(jQuery);
