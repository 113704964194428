(function ($) {

  "use strict";

  function Common () {
    let bindSmoothScrollToElement = function () {
      // handle links with @href started with '#' only
      $(document).on('click', 'a[href^="#"]:not([data-toggle="tab"])', function(e) {
        // target element id
        var id = $(this).attr('href');

        // target element
        var $id = $(id);
        if ($id.length === 0) {
          return;
        }

        // prevent standard hash navigation (avoid blinking in IE)
        e.preventDefault();

        // top position relative to the document
        var pos = $id.offset().top;

        // animated top scrolling
        $('body, html').animate({scrollTop: pos});
      });
    };
    
    let bindMobileNavTrigger = function ($header) {
      // Trigger for toggling visibility of mobile navigation START
      let $navTrigger = $('.js-nav-trigger', $header);

      if ($navTrigger.is('*')) {
        let $navigation = $('.js-nav', $header);

        $navTrigger.on('click', function () {
          let $self = $(this);

          $self.find('> div').toggleClass('active');
          $navigation.toggleClass('active');
        });
      }
      // Trigger for toggling visibility of mobile navigation STOP
    };
    
    let bindFixedHeader = function ($header, $body) {
      let $navTrigger = $('.js-fixed-header-nav-trigger', $body);

      if ($navTrigger.is('*')) {
        let $menuOverlay = $('.js-menu-overlay', $body);
        let $closeMenuOverlay = $('.js-menu-overlay-close', $menuOverlay);

        $navTrigger.on('click', function () {
          let $self = $(this);

          $self.find('> div').toggleClass('active');
          $menuOverlay.toggleClass('active');
        });

        $closeMenuOverlay.on('click', function () {
          $menuOverlay.removeClass('active');
          $navTrigger.find('> div').removeClass('active');
        });
      }

      let $fixedHeader = $('.js-fixed-header');
      let headerHeight = $header.outerHeight();
      
      $(window).scroll(function () {
        let scrollFromTop = $(window).scrollTop();

        $fixedHeader.toggleClass('active', scrollFromTop > headerHeight);
      });

      $fixedHeader.toggleClass('active', $(window).scrollTop() > headerHeight);
    };
    
    let bindLangSwitcher = function () {
      let $lang = $('.js-lang');
      let $langBtn = $('.js-lang-btn');
      let $langList = $('.js-lang-list');

      $langBtn.on('click', function (event) {
        event.stopPropagation();
        let $self = $(this);
        let $selfLang = $self.closest($lang);
        let $selfList = $selfLang.find($langList);

        $selfList.toggleClass('active');
      });

      $(window).click(function() {
        $langList.removeClass('active');
      });
    };

    let bindShowMoreAndMasonry = function ($showMoreBtn, $masonry) {
      let masonryOptions = {
        itemSelector: 'li',
        percentPosition: true,
      };
      
      if ($masonry.length) {
        $masonry.masonry(masonryOptions);
      }

      if ($showMoreBtn.length) {
        $showMoreBtn.on('click', function () {
          let $self = $(this);
          let $selfContent = $self.closest('.js-show-more-content');
          let $toggleContent = $selfContent.find('.js-show-more-toggle-element');

          $selfContent.toggleClass('active');
          $toggleContent.slideToggle(300);

          setTimeout(function () {
            $masonry.masonry(masonryOptions);
          }, 300);
        });
      }
    };

    let init = function (parentElement) {
      let $header = $('.js-header', parentElement);
      let $showMoreBtn = $('.js-show-more-btn', parentElement);
      let $masonry = $('.js-masonry-init', parentElement);
      
      if ($header.length) {
        bindSmoothScrollToElement();
        bindMobileNavTrigger($header);
        bindFixedHeader($header, parentElement);
        bindLangSwitcher($header);
        
        $('.js-sub-nav-trigger').on('click', function () {
          $(this).parent().toggleClass('nav-opened');
        });
      }

      bindShowMoreAndMasonry($showMoreBtn, $masonry);
    };

    this.init = function (element) {
      let parentElement = element ? element : $('body');
      init(parentElement);
    };
  }

  window.Common = new Common();

})(jQuery);
