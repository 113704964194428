(function ($) {

  'use strict';

  let initFunctions = {
    initOnce: function () {
      initFunctions.init();
    },

    init: function (area) {
      let initArea = (area) ? area : $('body');
      
      /*   components   */
      /**************************/
      Common.init(initArea);
      SwiperSlider.init(initArea);
      MagnificPopup.init(initArea);
      
      if (DeviceSelector.mobileAll()) {
        // MobileOverlay.init(initArea);
      }
    }
  };

  $(document).ready(function () {
    initFunctions.initOnce();
  });

})(jQuery);
